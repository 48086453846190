export enum CheckoutStepNames {
  Questionnaire = 'questionnaire ', // Questionnaire
  Identification = 'identification', // normal identification component
  Investment = 'amount', // CheckoutInvestment
  Terms = 'terms', // CheckoutLegal
  Payment = 'payment', // no component
  Status = 'status', // CheckoutStatus
}

export type Checkout = {
  assetId: string,
  sharesAmount: number,
  eurAmount: number,
  totalEur: number,
  totalEmissionCosts: number,
  selectedDividendsFormatYear: [string, number],
  nextStep?: CheckoutStepNames | 'lastStep',
  assetValuationId: string | undefined;
};

export interface CheckoutCloudFunctionParameters {
  assetId: string,
  redirectUrl: string,
  sharesAmount: number,
  lang: string,
  selectedDividendsFormatYear: [string, number],
  brand?: string
  type: undefined | 'loan', // this is not a parameter in the CF but we still send it for now
  assetValuationId: string | undefined;
}

export type Step = {
  name: CheckoutStepNames,
  /**
   * what is `matched` in the router
   */
  route: string,
  /**
   * the pointer to the correct v18n property
   */
  display: string,
};
