export const RESET_STATE = 'RESET_STATE';
export const SHOW_GDPR = 'SHOW_GDPR';
export const CLOSE_GDPR = 'CLOSE_GDPR';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_PROCESSING = 'LOGIN_PROCESSING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_WARNING = 'LOGIN_WARNING';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_PROCESSING = 'SIGNUP_PROCESSING';
export const CONTACT_SUPPORT_ERROR = 'CONTACT_SUPPORT_ERROR';
export const CONTACT_SUPPORT_SUCCESS = 'CONTACT_SUPPORT_SUCCESS';
export const CONTACT_SUPPORT_PROCESSING = 'CONTACT_SUPPORT_PROCESSING';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_PROCESSING = 'LOGOUT_PROCESSING';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_PROCESSING = 'RESET_PASSWORD_PROCESSING';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_PROCESSING = 'CHANGE_PASSWORD_PROCESSING';
export const CHANGE_NAME_SURNAME_ERROR = 'CHANGE_NAME_SURNAME_ERROR';
export const CHANGE_NAME_SURNAME_SUCCESS = 'CHANGE_NAME_SURNAME_SUCCESS';
export const CHANGE_NAME_SURNAME_PROCESSING = 'CHANGE_NAME_SURNAME_PROCESSING';
export const CHANGE_EMAIL_ERROR = 'CHANGE_EMAIL_ERROR';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_PROCESSING = 'CHANGE_EMAIL_PROCESSING';
export const SEND_EMAIL_VERIFICATION_ERROR = 'SEND_EMAIL_VERIFICATION_ERROR';
export const SEND_EMAIL_VERIFICATION_SUCCESS = 'SEND_EMAIL_VERIFICATION_SUCCESS';
export const SEND_EMAIL_VERIFICATION_PROCESSING = 'SEND_EMAIL_VERIFICATION_PROCESSING';
export const SEND_QUESTIONNAIRE_ANSWERS_ERROR = 'SEND_QUESTIONNAIRE_ANSWERS_ERROR';
export const SEND_QUESTIONNAIRE_ANSWERS_SUCCESS = 'SEND_QUESTIONNAIRE_ANSWERS_SUCCESS';
export const SEND_QUESTIONNAIRE_ANSWERS_PROCESSING = 'SEND_QUESTIONNAIRE_ANSWERS_PROCESSING';
export const SEND_PRIVATE_IDENTIFICATION_ERROR = 'SEND_PRIVATE_IDENTIFICATION_ERROR';
export const SEND_PRIVATE_IDENTIFICATION_SUCCESS = 'SEND_PRIVATE_IDENTIFICATION_SUCCESS';
export const SEND_PRIVATE_IDENTIFICATION_PROCESSING = 'SEND_PRIVATE_IDENTIFICATION_PROCESSING';
export const SEND_BUSINESS_IDENTIFICATION_ERROR = 'SEND_BUSINESS_IDENTIFICATION_ERROR';
export const SEND_BUSINESS_IDENTIFICATION_SUCCESS = 'SEND_BUSINESS_IDENTIFICATION_SUCCESS';
export const SEND_BUSINESS_IDENTIFICATION_PROCESSING = 'SEND_BUSINESS_IDENTIFICATION_PROCESSING';
export const CHANGE_BANK_ACCOUNT_EMAIL_ERROR = 'CHANGE_BANK_ACCOUNT_EMAIL_ERROR';
export const CHANGE_BANK_ACCOUNT_EMAIL_SUCCESS = 'CHANGE_BANK_ACCOUNT_EMAIL_SUCCESS';
export const CHANGE_BANK_ACCOUNT_EMAIL_PROCESSING = 'CHANGE_BANK_ACCOUNT_EMAIL_PROCESSING';
export const CHANGE_BANK_ACCOUNT_ERROR = 'CHANGE_BANK_ACCOUNT_ERROR';
export const CHANGE_BANK_ACCOUNT_SUCCESS = 'CHANGE_BANK_ACCOUNT_SUCCESS';
export const CHANGE_BANK_ACCOUNT_PROCESSING = 'CHANGE_BANK_ACCOUNT_PROCESSING';
export const EMAIL_VERIFICATION_ERROR = 'EMAIL_VERIFICATION_ERROR';
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';
export const EMAIL_VERIFICATION_PROCESSING = 'EMAIL_VERIFICATION_PROCESSING';
export const CONFIRM_PASSWORD_RESET_ERROR = 'CONFIRM_PASSWORD_RESET_ERROR';
export const CONFIRM_PASSWORD_RESET_SUCCESS = 'CONFIRM_PASSWORD_RESET_SUCCESS';
export const CONFIRM_PASSWORD_RESET_PROCESSING = 'CONFIRM_PASSWORD_RESET_PROCESSING';
export const CREATE_DATA_CHANGE_REQUEST_ERROR = 'CREATE_DATA_CHANGE_REQUEST_ERROR';
export const CREATE_DATA_CHANGE_REQUEST_SUCCESS = 'CREATE_DATA_CHANGE_REQUEST_SUCCESS';
export const CREATE_DATA_CHANGE_REQUEST_PROCESSING = 'CREATE_DATA_CHANGE_REQUEST_PROCESSING';
export const RECOVER_EMAIL_ERROR = 'RECOVER_EMAIL_ERROR';
export const RECOVER_EMAIL_SUCCESS = 'RECOVER_EMAIL_SUCCESS';
export const RECOVER_EMAIL_PROCESSING = 'RECOVER_EMAIL_PROCESSING';
export const CHECK_ACTION_CODE_ERROR = 'CHECK_ACTION_CODE_ERROR';
export const CHECK_ACTION_CODE_SUCCESS = 'CHECK_ACTION_CODE_SUCCESS';
export const CHECK_ACTION_CODE_PROCESSING = 'CHECK_ACTION_CODE_PROCESSING';
export const IDLE_LOGOUT = 'IDLE_LOGOUT';
export const SHOW_INITIAL_TOOLTIP = 'SHOW_INITIAL_TOOLTIP';
export const HIDE_INITIAL_TOOLTIP = 'HIDE_INITIAL_TOOLTIP';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_PROCESSING = 'UPDATE_USER_PROCESSING';
export const IDIN_TRANSACTION_ERROR = 'IDIN_TRANSACTION_ERROR';
export const IDIN_TRANSACTION_SUCCESS = 'IDIN_TRANSACTION_SUCCESS';
export const IDIN_TRANSACTION_PROCESSING = 'IDIN_TRANSACTION_PROCESSING';
export const IDIN_GET_STATUS_ERROR = 'IDIN_GET_STATUS_ERROR';
export const IDIN_GET_STATUS_PROCESSING = 'IDIN_GET_STATUS_PROCESSING';
export const IDIN_GET_STATUS_SUCCESS = 'IDIN_GET_STATUS_SUCCESS';
export const RESEND_SETPASSWORD_EMAIL_ERROR = 'RESEND_SETPASSWORD_EMAIL_ERROR';
export const RESEND_SETPASSWORD_EMAIL_SUCCESS = 'RESEND_SETPASSWORD_EMAIL_SUCCESS';
export const RESEND_SETPASSWORD_EMAIL_PROCESSING = 'RESEND_SETPASSWORD_EMAIL_PROCESSING';
export const REVERT_SECOND_FACTOR_PROCESSING = 'REVERT_SECOND_FACTOR_PROCESSING';
export const REVERT_SECOND_FACTOR_ERROR = 'REVERT_SECOND_FACTOR_ERROR';
export const REVERT_SECOND_FACTOR_SUCCESS = 'REVERT_SECOND_FACTOR_SUCCESS';
export const ASSET_RESERVATION_PROCESSING = 'ASSET_RESERVATION_PROCESSING';
export const ASSET_RESERVATION_ERROR = 'ASSET_RESERVATION_ERROR';
export const ASSET_RESERVATION_SUCCESS = 'ASSET_RESERVATION_SUCCESS';
export const UPDATE_BLOCKCHAINTOKENS_PROCESSING = 'UPDATE_BLOCKCHAINTOKENS_PROCESSING';
export const UPDATE_BLOCKCHAINTOKENS_ERROR = 'UPDATE_BLOCKCHAINTOKENS_ERROR';
export const UPDATE_BLOCKCHAINTOKENS_SUCCESS = 'UPDATE_BLOCKCHAINTOKENS_SUCCESS';
export const UPDATE_IS_METAMASK_CONNECTED_PROCESSING = 'UPDATE_IS_METAMASK_CONNECTED_PROCESSING';
export const UPDATE_IS_METAMASK_CONNECTED_ERROR = 'UPDATE_IS_METAMASK_CONNECTED_ERROR';
export const UPDATE_IS_METAMASK_CONNECTED_SUCCESS = 'UPDATE_IS_METAMASK_CONNECTED_SUCCESS';
export const CLAIM_BLOCKCHAIN_TOKENS_PROCESSING = 'CLAIM_BLOCKCHAIN_TOKENS_PROCESSING';
export const CLAIM_BLOCKCHAIN_TOKENS_ERROR = 'CLAIM_BLOCKCHAIN_TOKENS_ERROR';
export const CLAIM_BLOCKCHAIN_TOKENS_SUCCESS = 'CLAIM_BLOCKCHAIN_TOKENS_SUCCESS';
