import to from 'await-to-js';
import { functions } from '@/firebase';

interface HubspotRegisterEventPayload {
  type: 'register'
  email: string;
  phone: string;
}

interface HubspotIdentificationEventPayload {
  type: 'identification'
  firstname: string;
  lastname: string;
}

interface HubspotReservationEventPayload {
  type: 'reservation'
  investeringsbedrag: number;
  email: string;
  project: string;
}

type SendHubspotEventPayload = HubspotRegisterEventPayload | HubspotIdentificationEventPayload | HubspotReservationEventPayload

export async function sendHubspotEvent(payload: SendHubspotEventPayload): Promise<any> {
  const [sendHubspotEventError, sendHubspotEventSuccess] = await to(
    functions.httpsCallable('sendHubspotEvent')({
      payload,
      pageName: window.location.pathname,
      pageUri: window.location.host,
    }),
  );

  if (sendHubspotEventError) {
    throw sendHubspotEventError;
  }

  return sendHubspotEventSuccess;
}
