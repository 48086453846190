import { Module } from 'vuex';
import { State } from '@/store/models';
import { Valuation } from '@/store/models/valuation';

export default <Module<Valuation[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getActiveValuationByAsset: (state): Function =>
      (assetId: string): Valuation | undefined => state
          .filter((valuation): boolean => valuation?.asset.id === assetId && valuation.date.toDate() <= new Date())
          .sort((aValuation, bValuation): any => bValuation.date.seconds - aValuation.date.seconds)[0],
  },
};
