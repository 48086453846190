import { Module } from 'vuex';
import { State } from '@/store/models';
import authModal from './authModal';
import idin from './idin';
import payment from './payment';
import investments from './investments';
import earnings from './earnings';
import payments from './payments';
import assets from './assets';
import identificationChecks from './identificationChecks';
import downloads from './downloads/downloads';
import checkout from './checkout';
import valuations from './valuations';
import blockchain from './blockchain';

export default <{ [key: string]: Module<any, State> }>{
  idin,
  payment,
  investments,
  earnings,
  payments,
  assets,
  identificationChecks,
  downloads,
  authModal,
  checkout,
  valuations,
  blockchain,
};
