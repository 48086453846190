import { Module } from 'vuex';
import { State } from '@/store/models';
import { Investment } from '@/store/models/investment';
import { Asset } from '@/store/models/asset';
import { formatNumber } from '@/filters/number';
import BigNumber from 'bignumber.js';

export interface InvestmentsArray<T> extends Array<T> {
  totalLength?: number;
}

/**
 * Function that shows two decimals if there are any
 * @param numb
 */
const showTwoDecimalsOrNone = (numb): number => numb % 1 !== 0 ? Number(formatNumber(numb, 2)) : numb;

export default <Module<Investment[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    investmentHasPaidPayments: (state, getters): Function => (id: string): boolean => !!getters.getPaymentsByInvestmentId(id).length,
    investmentsLoadMore: (state, getters): Function => (position: number): Investment[] => {
      const tempInvestments = getters.getInvestmentsNonDeletedAsset as Investment[];
      const paidInvestments = tempInvestments.filter((investment: Investment): boolean => getters.investmentHasPaidPayments(investment.id));
      const investments: InvestmentsArray<any> = paidInvestments.slice(0, position > tempInvestments.length ? tempInvestments.length : position);

      investments.totalLength = paidInvestments.length;

      return investments;
    },
    // Since we cannot do a proper 'join' to get invesments with conditioned asset, we need to do it client side
    getInvestmentsNonDeletedAsset: (state): Investment[] => state.filter(
      (investment): boolean => !!investment.asset.id && !(investment.asset as Asset).deleted && (investment.asset as Asset).published,
    ),
    getInvestmentById: (state): Function =>
      (id: string): Investment | undefined =>
        state.find((investment): boolean => investment.id === id),
    getInvestmentByAsset: (state): Function =>
      (assetId: string): Investment | undefined =>
        state.find((investment): boolean => investment.asset.id === assetId),
    // Get the number of investments that have at least one paid payment
    getLengthPaidInvestments: (state, getters): number => state.filter(
      (investment): boolean => getters.investmentHasPaidPayments(investment.id),
    ).length,
    // Get the total Euro invested from all investments (if status of payment is 'paid')
    getTotalInvested: (state): number =>
      state.reduce((investmentA, investmentB): number =>
        investmentA + (investmentB.paidEuroTotal || 0), 0),
    getPortfolioValue: (state, getters): number => {
      let portfolioValue = 0;
      state.forEach((investment): void => {
        const assetValuationSharePrice = getters.getAssetValuationSharePrice(investment.asset.id);
        if (assetValuationSharePrice && typeof assetValuationSharePrice === 'number') {
          portfolioValue += new BigNumber(assetValuationSharePrice).times(investment.boughtSharesTotal!).toNumber();
        } else {
          portfolioValue += investment.paidEuroTotal ? investment.paidEuroTotal : 0;
        }
      });
      return portfolioValue;
    },
    getPortfolioPerformance: (state, getters): { performanceStatus: string, eurosDifference: number, percentageDifference: number } => {
      const portfolioPerformance = getters.getPortfolioValue;
      const getTotalInvested = getters.getTotalInvested;

      if (portfolioPerformance && getTotalInvested) {
        const percentageDifference = new BigNumber(portfolioPerformance).minus(getTotalInvested).dividedBy(getTotalInvested).times(100)
          .toNumber();
        const eurosDifference = portfolioPerformance - getTotalInvested;
        let performanceStatus = 'neutral';

        if (eurosDifference > 0) {
          performanceStatus = 'positive';
        } else if (eurosDifference < 0) {
          performanceStatus = 'negative';
        }

        return {
          performanceStatus,
          eurosDifference,
          percentageDifference,
        };
      }

      return {
        performanceStatus: 'neutral',
        eurosDifference: 0,
        percentageDifference: 0,
      };
    },
    getSharesTotalInvested: (state): number =>
      state.reduce((investmentA, investmentB): number => investmentA + (investmentB.boughtSharesTotal || 0), 0),
    getTotalEarnings: (state): number =>
      state.reduce((totalEarnings, currentInvestment): BigNumber => totalEarnings.plus(currentInvestment.totalNetEarnings || 0), new BigNumber(0)).dp(2).toNumber(),
  },
};
