import { Module } from 'vuex';
import { State } from '@/store/models';
import { Earning } from '@/store/models/investment';
import BigNumber from 'bignumber.js';
import moment from 'moment';

export default <Module<Earning[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getMonthlyEarnings: (state): number => {
      const earningsWithBiggestPeriod = state.reduce((prev, current): any => ((prev.period.toDate().valueOf() < current.period.toDate().valueOf())
        && current.period.toDate().valueOf() <= moment().valueOf()) ? current : prev);

      const summedNetAmount = state
        .filter((earning): boolean => {
          const lastPeriodWithEarnings = earningsWithBiggestPeriod.period.toDate().valueOf();
          const periodMoment = moment(earning.period.toDate());
          return periodMoment.valueOf() === lastPeriodWithEarnings;
        })
        .reduce(((accumulator, item): BigNumber => accumulator.plus(item.netAmount)), new BigNumber(0));

      return summedNetAmount.dp(2).toNumber();
      // leaving the following logic commented here because this approach makes more sense
      // for now they choose to only add one dividend each month so had to make it more simple

      // const oneMonthAgo = moment().subtract(1, 'month');
      // const lastMonthEarnings = state.filter((earning): boolean => moment(earning.period.toDate()).valueOf() > oneMonthAgo.valueOf());
      // return lastMonthEarnings.reduce((totalEarnings, currentEarning): BigNumber => totalEarnings.plus(currentEarning.netAmount || 0), new BigNumber(0)).toNumber(); }
    },
    getYearlyEarnings: (state, getters): number => new BigNumber(getters.getMonthlyEarnings).times(12).toNumber(),
      // leaving the following logic commented here because this approach makes more sense
      // for now they choose to only add one dividend each month so had to make it more simple

      // const oneYearAgo = moment().subtract(1, 'year');
      // const lastYearEarnings = state.filter((earning): boolean => moment(earning.period.toDate()).valueOf() > oneYearAgo.valueOf());
      // return lastYearEarnings.reduce((totalEarnings, currentEarning): BigNumber => totalEarnings.plus(currentEarning.netAmount || 0), new BigNumber(0)).toNumber();
  },
};
