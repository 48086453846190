import firebase from 'firebase';
import { Module } from 'vuex';
import { State } from '@/store/models';
import { Payment, PaymentStatus } from '@/store/models/investment';
import { formatNumber } from '@/filters/number';

export interface InvestmentsArray<T> extends Array<T> {
  totalLength?: number;
}

export interface CombinedDividendsFormat {
  euroAmountDividends: number,
  dividendsFormat: [string, number]
}

/**
 * Function that shows two decimals if there are any
 * @param numb
 */
const showTwoDecimalsOrNone = (numb): number => numb % 1 !== 0 ? Number(formatNumber(numb, 2)) : numb;

// get the paymentDate if paymentDate itself is undefined
export const getPaymentDate = (payment: Payment): firebase.firestore.Timestamp => payment.paymentDateTime ?? payment.updatedDateTime ?? payment.createdDateTime;

export default <Module<Payment[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getPaymentById: (state): Function =>
      (paymentId: string): Payment | undefined => state.find((payment): boolean => payment.id === paymentId),
    getPaymentByAsset: (state): Function =>
      (assetId: string): Payment | undefined =>
        state.find((payment): boolean => payment.asset.id === assetId),
    getPaymentsByInvestmentId: (state): Function =>
      (investmentId: string): Payment[] | undefined => state.filter((payment): boolean => payment.investment.id === investmentId),
    getPaidPaymentsByInvestmentId: (state): Function =>
      (investmentId: string): Payment[] | undefined => state.filter(
        (payment): boolean => payment.investment.id === investmentId && payment.providerData.status === PaymentStatus.Paid && !payment.deleted,
      ).sort((a, b): number => getPaymentDate(b).toMillis() - getPaymentDate(a).toMillis()),
    getPaidPayments: (state): Payment[] => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Paid,
    ),
    // Get the number of investments that have at least one paid payment
    getLengthPaidPayments: (state): number => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Paid,
    ).length,
    getDividendsByAssetYearly: (state): Function =>
      (assetId: string): [string, number][] => state.reduce((divByAsset, payment): [string, number][] => {
        const tempDivByAsset = [...divByAsset];
        if (payment.asset.id === assetId) {
          tempDivByAsset.push([
            payment.dividendsFormat[0],
            showTwoDecimalsOrNone(payment.providerData.metadata.euroAmount * (payment.dividendsFormat[1] / 100)),
          ]);
        }
        return divByAsset;
      }, [] as [string, number][]),
    getTotalDividendsPerYear: (state, getters): number => {
      const result = getters.getPaidPayments.reduce((paymentA, paymentB): number => paymentA + (
        !paymentB.ended ? paymentB.providerData.metadata.euroAmount * (paymentB.dividendsFormat[1] / 100) : 0
      ), 0);

      // Only 2 decimals
      return showTwoDecimalsOrNone(result);
    },
    getTotalDividendsPerMonth: (state): number => {
      const result = state.reduce((paymentA, paymentB): number => paymentA + (
        // Only for paid payments
        paymentB.providerData.status === PaymentStatus.Paid && !paymentB.ended && !paymentB.deleted
          ? (paymentB.providerData.metadata.euroAmount * (paymentB.dividendsFormat[1] / 100)) / 12
          : 0
      ), 0);

      return showTwoDecimalsOrNone(result);
    },
    hasDifferentPaymentFormatsByAsset: (state): Function => (assetId: string): boolean => {
      const dividendFormats: Payment['dividendsFormat'][] = [];
      let whileIndex = 0;
      while (dividendFormats.length < 2 && whileIndex < state.length) {
        const payment = state[whileIndex];
        if (payment.asset.id === assetId) {
          if (!dividendFormats.some((format): boolean => payment.dividendsFormat[0] === format[0] && payment.dividendsFormat[1] === format[1])) {
            dividendFormats.push(payment.dividendsFormat);
          }
        }
        whileIndex++;
      }
      return dividendFormats.length > 1;
    },
    getCombinedDividendsFormatByAsset: (state, getters): Function => (assetId: string): CombinedDividendsFormat => {
      const group: CombinedDividendsFormat = getters.getPaidPayments.reduce((a, b): CombinedDividendsFormat => {
        if (b.asset.id !== assetId && !b.ended) {
          return a;
        }
        // Creating the object with the next payment
        const final: CombinedDividendsFormat = {
          ...a,
          [b.dividendsFormat[0]]: {
            euroAmount: b.providerData.metadata.euroAmount,
            sharesAmount: b.providerData.metadata.sharesAmount,
            percentage: b.dividendsFormat[1],
            dividends: (b.providerData.metadata.euroAmount * b.dividendsFormat[1]) / 100,
          },
        };
        // Doing the actual reduction
        if (a[b.dividendsFormat[0]]) {
          final[b.dividendsFormat[0]].euroAmount += a[b.dividendsFormat[0]].euroAmount;
          final[b.dividendsFormat[0]].sharesAmount += a[b.dividendsFormat[0]].sharesAmount;
          final[b.dividendsFormat[0]].dividends += a[b.dividendsFormat[0]].dividends;
        }
        return final;
      }, {});

      return group;
    },
  },
};
